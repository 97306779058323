
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as _ from 'lodash'
// import enumservice from "../../../services/enumservice";
import workhoursservice from '../../services/workhours/workhours'
import projectService from '../../services/projectmanager/projectservice'
import productservice from '../../services/projectmanager/productservice'
import loginService from '../../services/login'
import orgService from '../../services/org/orgservice'
// import { userInfo } from "os";
export default {
  mounted() {
    this.loading = true
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    this.login_code = userInfo['username']
    var depCode = userInfo['dep_code']
    if (depCode.length >= 6 && depCode.slice(0, 6) == '001001') {
      this.userType = '1'
    }
    if (depCode.length >= 6 && depCode.slice(0, 6) == '001002') {
      this.userType = '0'
    }
    this.audit = userInfo['audit']
    this.init()
    this.getOrgInfo()
    this.getProduct()
    this.getProject()
    this.getAllUser()
    this.getAllClass(null)
    //this.getOrgExceptAdmin();
    // this.createTour();
    // this.tour.start();
  },
  data() {
    const userInfoString = localStorage.getItem('userInfo')
    const userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
    return {
      tour: null,
      userInfo,
      lodash: _,
      searchWord: '',
      tableData: [],
      parentItems: [],
      childItems: [],
      allChildItems: [],
      percentState: false,
      currentPage: 1,
      tableDataLength: 0,
      pageSize: 15,
      loading: false,
      calcHeight: document.documentElement.clientHeight - 60,
      addDialogVisible: false,
      status: '',
      title: '',
      form: {},
      login_code: '',
      project_code: '',
      date: '',
      formInline: {},
      formHead: {},
      addTable: [
        {
          project_code: '',
          project_name: '',
          jira_code: '',
          class: '',
          audit: userInfo['audit'],
          hours: '',
          pro_des: '',
          childItems: [],
        },
      ],
      projectList: [],
      projectNameList: [],
      options: [
        {
          value: '1',
          label: '预研',
        },
        {
          value: '0',
          label: '需求',
        },
        {
          value: '2',
          label: '设计',
        },
        {
          value: '3',
          label: '开发',
        },
        {
          value: '4',
          label: '实施',
        },
        {
          value: '5',
          label: '支持',
        },
        {
          value: '6',
          label: '其他',
        },
        {
          value: '7',
          label: '测试',
        },
      ],
      rules: {
        date: [
          {
            required: true,
            message: '请选择日期',
            trigger: 'change',
          },
        ],
        audit: [
          {
            required: true,
            message: '请输入审核人名字',
            trigger: 'blur',
          },
        ],
      },
      workHoursDetail: {},
      userList: [],
      dialogVisible: false,
      formAudit: {},
      productOptions: [],
      orgList: [],
      orgProjectList: [],
      userType: '',
      showConfirm: '',
    }
  },
  methods: {
    // 初始化获取信息
    async init() {
      this.loading = true
      const params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        login_code: this.login_code,
        project_code: this.project_code,
        startDate: this.date ? this.date[0] : this.date,
        endDate: this.date ? this.date[1] : this.date,
      }
      const hoursResult: any = await workhoursservice.getWorkhoursList(params)
      if (hoursResult.success) {
        this.tableData = hoursResult.rows
        this.tableDataLength = hoursResult.total
      } else {
        this.$message.warning(hoursResult.msg)
      }
      this.loading = false
    },
    createTour(){
      this.tour = this.$shepherd({
        useModalOverlay: true
      });
      const buttons = [
        {
          action() {
            return this.back();
          },
          classes: 'shepherd-button-secondary',
          text: 'Back'
        },
        {
          action() {
            return this.next();
          },
          text: 'Next'
        }
      ]
      this.tour.addSteps([
        {
          attachTo: { element: this.$refs.el, on: 'top' },
          text: 'Test',
          buttons
        },
        {
          attachTo: { element: this.$refs.el1, on: 'top' },
          text: 'Test',
          buttons
        },
        {
          attachTo: { element:  '.single-table', on: 'top' },
          text: 'Test',
          buttons
        },
        {
          attachTo: { element: '.page-show', on: 'bottom' },
          text: 'Test',
          buttons
        }
      ]);
    },
    async getLastTimesWork() {
      let params = {
        login_code: this.login_code,
      }
      this.addTable = []
      let temptable = []
      const items: any = await workhoursservice.getLastTimeWork({
        params: params,
      })
      if (items.success) {
        for (var i = 0; i < items.rows.length; i++) {
          const item = items.rows[i]
          const typeitem: any = _.filter(this.parentItems, {
            value: item.class,
          })[0]
          // await this.getAllClass(typeitem.id)
          var obj = {
            project_code: item.project_code,
            project_name: item.project_name,
            audit: item.audit,
            jira_code: '',
            date: this.formHead.date,
            class: item.class || '',
            class_child: item.class_child || '',
            hours: 8,
            pro_des: '',
            childItems: _.cloneDeep(this.allChildItems),
          }
          temptable.push(obj)
        }
        this.addTable = this.addTable.concat(temptable)
        return
      } else {
        this.$message.warning('获取上次填写内容失败')
      }
      this.addTable = [
        {
          project_code: '',
          project_name: '',
          jira_code: '',
          class: '3',
          date: this.formHead.date,
          audit: this.userInfo['audit'],
          hours: 8,
          pro_des: '',
        },
      ]
    },
    async getOrgInfo() {
      const result: any = await orgService.getOrgInfo()
      if (result.success) {
        this.orgList = result.rows
      } else {
        this.$message.warning(result.msg)
      }
    },
    async getProduct() {
      const params = {
        currentPage: 1,
        pageSize: 300,
      }
      const result: any = await productservice.getProductList(params)
      if (result.success) {
        this.productOptions = result.rows
      } else {
        this.$message.warning(result.msg)
      }
    },
    // 获取分类数据
    async getAllClass(parentId) {
      const params = {
        parent_id: parentId,
        // dep_code: this.userInfo['dep_code'].substr(0,6),
      }
      const typeResult: any = await workhoursservice.getWorkHourClass(params)
      if (!parentId) {
        this.parentItems = _.filter(typeResult.rows, { parent_id: null })
        this.allChildItems = _.filter(typeResult.rows, function (item) {
          return item.parent_id !== null
        })
        this.activeItem = this.parentItems[0].name
        this.getAllClass(this.parentItems[0].id)
      } else {
        this.childItems = typeResult.rows
      }
    },
    async parentTypeChanged(data, row) {
      row.class_child = ''
      const item: any = _.filter(this.parentItems, { value: data })[0]
      await this.getAllClass(item.id)
      row.childItems = _.cloneDeep(this.childItems)
    },
    async updateAudit(row) {
      const params = {
        code: this.login_code,
        audit: this.formAudit.audit,
      }
      const result: any = await loginService.updateUser(params)
      if (result.success) {
        this.dialogVisible = false
        this.formHead.audit = this.formAudit.audit
        this.$message.success('修改成功')
      } else {
        this.$message.warning(result.msg)
      }
    },
    async getWorkHoursDetail(row) {
      const params = {
        id: row.id,
        login_code: row.login_code,
        date: row.date,
      }
      const hoursResult: any = await workhoursservice.getWorkHoursDetail(params)
      if (hoursResult.success) {
        // this.addTable = hoursResult.body.rows;
        for (let i = 0; i < hoursResult.body.rows.length; i++) {
          const item = hoursResult.body.rows[i]
          console.log(item)
          const typeitem: any = _.filter(this.parentItems, {
            value: item.class,
          })
          if (typeitem[0]) {
            const id = typeitem[0].id
            // await this.getAllClass(id)
            item.childItems = _.cloneDeep(this.allChildItems)
          }
          this.addTable.push(item)
        }
        this.formHead = hoursResult.rows[0]
      } else {
        this.$message.warning(hoursResult.msg)
      }
    },
    close() {
      this.$refs['formHead'].clearValidate()
    },
    editProject(row) {
      this.title = '编辑日志'
      this.status = 'edit'
      this.addDialogVisible = true
      this.addTable = []
      this.getWorkHoursDetail(row)
    },
    detailProject(row) {
      this.title = '日志详情'
      this.status = 'detail'
      this.addDialogVisible = true
      this.addTable = []
      this.getWorkHoursDetail(row)
    },
    async addProject() {
      const typeitem: any = _.filter(this.parentItems, { value: '11' })[0]
      await this.getAllClass(typeitem.id)
      var obj = {
        project_code: '',
        project_name: '',
        jira_code: '',
        product_code: '',
        class: '11',
        child_class: '',
        date: this.formHead.date,
        audit: this.userInfo['audit'],
        hours: 8,
        pro_des: '',
        childItems: _.cloneDeep(this.childItems),
      }

      this.addTable.push(obj)
    },
    add() {
      this.title = '添加日志'
      this.status = 'add'
      this.addDialogVisible = true
      let now: any = new Date()
      let year: any = now.getFullYear() //得到年份
      let month: any = now.getMonth() //得到月份
      let date: any = now.getDate() //得到日期
      month = month + 1
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      var defaultDate = `${year}-${month}-${date}`
      this.formHead = {
        date: defaultDate,
        audit: this.audit,
      }
      this.getLastTimesWork()
      // this.addTable = [
      //     {
      //         project_code: "",
      //         project_name: "",
      //         jira_code: "",
      //         class: "3",
      //         hours: 8,
      //         pro_des: ""
      //     }
      // ];
    },
    async getAllUser() {
      const items: any = await workhoursservice.getAllUser({})
      if (items.success) {
        var temp = items.rows
        for (var i = 0; i < temp.length; i++) {
          var obj = {}
          obj['value'] = temp[i].code
          obj['name'] = temp[i].name
          this.userList.push(obj)
        }
      } else {
        this.$message.warning(items.msg)
      }
    },
    async getProject() {
      let params = {
        project_status: JSON.stringify(['0', '1', '2', '3']),
        org: this.userInfo.dep_code.slice(0, 6),
      }
      const items: any = await projectService.getOnlyProject({
        params: params,
      })
      if (items.success) {
        var temp = items.rows
        this.orgProjectList = items.rows
        for (var i = 0; i < temp.length; i++) {
          var obj = {}
          var name = {}
          obj['value'] = temp[i].project_code
          obj['name'] = temp[i].project_name
          name['value'] = temp[i].project_name
          name['name'] = temp[i].project_code
          this.projectList.push(obj)
          this.projectNameList.push(name)
        }
      } else {
        this.$message.warning(items.msg)
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.projectList
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
          -1
        )
      }
    },
    handleSelect(code, index) {
      var item = _.find(this.projectList, {
        value: code,
      })
      var name = item['name']
      this.addTable[index]['project_name'] = name
      //this.addTable[index].project_name = name;
      var originProject: any = _.find(this.orgProjectList, {
        project_code: code,
      })
      if (originProject.project_stage == '2' || originProject.project_stage == '3' ) {
        this.$confirm('所选项目已终止或已结项，请确认')
      }
      if (
        originProject.dep_code.slice(0, 6) !==
        this.userInfo.dep_code.slice(0, 6)
      ) {
        this.$confirm('所选项目非本公司项目，是否继续？')
      }
    },
    handleSelect1(code, index) {
      var item = _.find(this.projectNameList, {
        value: code,
      })
      var name = item['name']
      this.addTable[index]['project_code'] = name
      //this.addTable[index].project_code = name;
      var originProject: any = _.find(this.orgProjectList, {
        project_name: code,
      })
      if (
        originProject.dep_code.slice(0, 6) !==
        this.userInfo.dep_code.slice(0, 6)
      ) {
        this.$confirm('所选项目非本公司项目，是否继续？')
      }
    },
    querySearch1(queryString, cb) {
      var restaurants = this.projectNameList
      var results = queryString
        ? restaurants.filter(this.createFilter1(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter1(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
          -1
        )
      }
    },
    deletePro(index) {
      this.addTable.splice(index, 1)
    },
    async deleteWorkhours(row) {
      var userInfoString = localStorage.getItem('userInfo')
      var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      var params = {
        id: row.id,
        login_code: userInfo['username'],
        date: row.date,
      }
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const result: any = await workhoursservice.deleteWorkhousrItem({
            params,
          })
          if (result.success) {
            this.$message({
              message: '删除成功',
              type: 'success',
            })
            this.init()
          }
        })
        .catch((err) => {
          this.$message({
            type: 'info',
            message: '取消删除',
          })
        })
    },

    checkRules() {
      var self = this
      this.$refs['formHead'].validate((valid) => {
        if (valid) {
          if (self.addTable.length == 0) {
            self.$message.error('请添加项目')
            return
          }
          self.addOrUpdate()
        } else {
          return false
        }
      })
    },
    // 小数点相加
    adddot() {
      var args = arguments, //获取所有的参数
        lens = args.length, //获取参数的长度
        d = 0, //定义小数位的初始长度，默认为整数，即小数位为0
        sum = 0 //定义sum来接收所有数据的和
      //循环所有的参数
      for (var key in args) {
        //遍历所有的参数
        //把数字转为字符串
        var str = '' + args[key]
        if (str.indexOf('.') != -1) {
          //判断数字是否为小数
          //获取小数位的长度
          var temp = str.split('.')[1].length
          //比较此数的小数位与原小数位的长度，取小数位较长的存储到d中
          d = d < temp ? temp : d
        }
      }
      //计算需要乘的数值
      var m = Math.pow(10, d)
      //遍历所有参数并相加
      for (var key in args) {
        sum += args[key] * m
      }
      //返回结果
      return sum / m
    },
    async doAdd() {
      var userInfoString = localStorage.getItem('userInfo')
      var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      var total = 0
      for (var i = 0; i < this.addTable.length; i++) {
        total = this.adddot(total, this.addTable[i].hours)
      }
      if (!userInfo['username']) {
        this.$message.error('登录信息失效，请重新登录')
        return
      }
      var head = {
        login_code: userInfo['username'],
        login_name: userInfo['name'],
        dep_code: userInfo['dep_code'],
        audit: this.formHead.audit,
        date: this.formHead.date,
        total: total,
      }
      var body = []
      for (var i = 0; i < this.addTable.length; i++) {
        var obj = {
          project_code: this.addTable[i].project_code,
          project_name: this.addTable[i].project_name,
          jira_code: this.addTable[i].jira_code,
          audit: this.addTable[i].audit,
          product_code: this.addTable[i].product_code,
          login_code: userInfo['username'],
          date: this.formHead.date,
          dep_code: userInfo['dep_code'],
          class: this.addTable[i].class,
          class_child: this.addTable[i].class_child,
          pro_des: this.addTable[i].pro_des,
          hours: this.addTable[i].hours,
        }
        body.push(obj)
      }
      var params = {
        head: head,
        body: body,
      }
      const addResult: any = await workhoursservice.addWorkhousrItem(params)
      if (addResult.success) {
        if (addResult.isExist) {
          this.$message.error(addResult.msg)
        } else {
          this.$message.success('添加成功')
          this.init()
        }
      } else {
        this.$message.warning(addResult.msg)
      }
    },
    async doEdit() {
      var userInfoString = localStorage.getItem('userInfo')
      var userInfo = userInfoString !== null ? JSON.parse(userInfoString) : {}
      var total = 0
      if (!userInfo['username']) {
        this.$message.error('登录信息失效，请重新登录')
        return
      }
      for (var i = 0; i < this.addTable.length; i++) {
        total = this.adddot(total, this.addTable[i].hours)
      }
      let head = {
        id: this.formHead.id,
        login_code: userInfo['username'],
        audit: this.formHead.audit,
        date: this.formHead.date,
        total: total,
      }
      var body = []
      for (var i = 0; i < this.addTable.length; i++) {
        var obj = {
          project_code: this.addTable[i].project_code,
          project_name: this.addTable[i].project_name,
          jira_code: this.addTable[i].jira_code,
          product_code: this.addTable[i].product_code,
          dep_code: userInfo['dep_code'],
          audit: this.addTable[i].audit,
          login_code: userInfo['username'],
          date: this.formHead.date,
          class: this.addTable[i].class,
          class_child: this.addTable[i].class_child,
          pro_des: this.addTable[i].pro_des,
          hours: this.addTable[i].hours,
        }
        body.push(obj)
      }
      let params = {
        head: head,
        body: body,
      }
      const addResult: any = await workhoursservice.updateWorkHours(params)
      if (addResult.success) {
        if (addResult.isExist) {
          this.$message.error(addResult.msg)
        } else {
          this.addDialogVisible = false
          this.$message.success('编辑成功')
          this.init()
        }
      } else {
        this.$message.warning(addResult.msg)
      }
    },
    async addOrUpdate() {
      var self = this
      if (this.status == 'add') {
        var reg = /[a-zA-Z0-9\u4E00-\u9FA5]/
        for (var i = 0; i < this.addTable.length; i++) {
          if (
            !this.addTable[i].project_code ||
            !this.addTable[i].project_name ||
            !this.addTable[i].class ||
            !this.addTable[i].audit ||
            !this.addTable[i].hours ||
            !reg.test(this.addTable[i].pro_des)
          ) {
            this.$message.error('请检查必填项是否完整')
            return
          }
        }
        if (this.userType == '0') {
          this.showConfirm = false
          for (var i = 0; i < this.addTable.length; i++) {
            if (this.addTable[i].project_code === 'RP880001' && this.addTable[i].class != '13') {
              alert('公共项目只能选其他分类')
              return;
            }
            if (this.addTable[i].project_code === 'RP880002' && (this.addTable[i].class != '13' ||  this.addTable[i].class_child != '133')) {
              alert('请假项目只能选其他分类下的请假')
              return;
            }
            if (this.addTable[i].project_code != 'RP880001' && this.addTable[i].project_code != 'RP880002' && this.addTable[i].class == '13') {
              alert('请假和公共项目以外的项目，都不能选其他分类')
              return;
            }
            if (!this.addTable[i].product_code) {
              this.showConfirm = true
              this.$confirm('未填写产品名称, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  self.doAdd()
                  return
                })
                .catch(() => {
                  return
                })
            }
            if (this.showConfirm == false && i == this.addTable.length - 1) {
              self.doAdd()
            }
          }
        } else {
          self.doAdd()
        }
      }
      if (this.status == 'edit') {
        for (var i = 0; i < this.addTable.length; i++) {
          if (
            !this.addTable[i].project_code ||
            !this.addTable[i].project_name ||
            !this.addTable[i].class ||
            !this.addTable[i].audit ||
            !this.addTable[i].hours ||
            !this.addTable[i].pro_des
          ) {
            this.$message.error('请检查必填项是否完整')
            return
          }
        }
        if (this.userType == '0') {
          this.showConfirm = false
          for (var i = 0; i < this.addTable.length; i++) {
            if (this.addTable[i].project_code === 'RP880001' && this.addTable[i].class != '13') {
              alert('公共项目只能选其他分类')
              return;
            }
            if (this.addTable[i].project_code === 'RP880002' && (this.addTable[i].class != '13' ||  this.addTable[i].class_child != '133')) {
              alert('请假项目只能选其他分类下的请假')
              return;
            }
            if (this.addTable[i].project_code != 'RP880001' && this.addTable[i].project_code != 'RP880002' && this.addTable[i].class == '13') {
              alert('请假和公共项目以外的项目，都不能选其他分类')
              return;
            }
            if (!this.addTable[i].product_code) {
              this.showConfirm = true
              this.$confirm('未填写产品名称, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  self.doEdit()
                  return
                })
                .catch(() => {
                  return
                })
            }
            if (this.showConfirm == false && i == this.addTable.length - 1) {
              self.doEdit()
            }
          }
        } else {
          self.doEdit()
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    handlePagenumChange(val) {
      this.currentPage = val
      this.init()
    },

    handleCurrentChange(val) {
      this.currentRow = val
    },

    queryClick() {
      ;(this.currentPage = 1), this.init()
    },
  },
}
